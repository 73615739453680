// Add your custom JS here.
// scripts.js
document.addEventListener('DOMContentLoaded', () => {
    const video = document.querySelector('#video');
    const playPauseButton = document.querySelector('#play-pause-button');
    const videoWrapper = document.querySelector('.video-wrapper');

    if (video) {

        playPauseButton.innerHTML = `
        <svg id="play-icon" viewBox="0 0 15 18">
          <path id="Polygon_2" data-name="Polygon 2" d="M8.143,1.429a1,1,0,0,1,1.715,0l7.234,12.056A1,1,0,0,1,16.234,15H1.766a1,1,0,0,1-.857-1.514Z" transform="translate(18) rotate(90)"/>
        </svg>

        <svg id="pause-icon" style="display:none;" viewBox="0 0 11 14">
            <g id="Group_2168" data-name="Group 2168" transform="translate(0 0)">
                <rect id="Rectangle_2648" data-name="Rectangle 2648" width="4" height="14" transform="translate(0 0)"/>
                <rect id="Rectangle_2649" data-name="Rectangle 2649" width="4" height="14" transform="translate(7 0)"/>
            </g>

        </svg>
    `;

        video.addEventListener('click', togglePlayPause);
        playPauseButton.addEventListener('click', togglePlayPause);
        video.addEventListener('play', updateButton);
        video.addEventListener('pause', updateButton);
        videoWrapper.addEventListener('mousemove', showButton);
        videoWrapper.addEventListener('mouseleave', checkButtonState);

        function togglePlayPause() {
            if (video.paused) {
                video.play();
            } else {
                video.pause();
            }
        }

        function updateButton() {
            const playIcon = document.querySelector('#play-icon');
            const pauseIcon = document.querySelector('#pause-icon');
            if (video.paused) {
                playIcon.style.display = '';
                pauseIcon.style.display = 'none';
                showButton();
            } else {
                playIcon.style.display = 'none';
                pauseIcon.style.display = '';
            }
        }

        function showButton() {
            playPauseButton.style.opacity = '1';
        }

        function checkButtonState() {
            if (!video.paused) {
                hideButton();
            }
        }

        function hideButton() {
            playPauseButton.style.opacity = '0';
        }

    }

});